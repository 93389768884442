import SocialButtonBar from "./components/SocialButtonsBar";

function App() {
  return (
    <div className="App">
      <h1>devsphere.xyz in progress...</h1>
      <SocialButtonBar/>
    </div>
  );
}

export default App;
