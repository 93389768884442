import "./StyleSocialButton.css";

export default function SocialButton({
    href,
    className,
    image,
    alt,
}) {
    return (
        <a 
            href={href}
            target="_blank"
            className={className}
            rel="noopener noreferrer"
        >
            <img
                src={image}
                alt={alt}
            />
        </a>        
    )
}