import SocialButton from "./SocialButton";
import "./StyleSocialButtonsBar.css";


const buttons = [
    {
        href: "https://t.me/NathanExplossion",
        className: "btn telegram",
        image: "/static/icon_monochrome_telegram_500.svg",
        alt: "Telegram",
    },
    {
        href: "https://wa.me/+77078783394",
        className: "btn whatsapp",
        image: "/static/icon_monochrome_whatsapp_500.svg",
        alt: "Whatsapp",
    },
    {
        href: "https://instagram.com/gilfoyle_kz",
        className: "btn instagram",
        image: "/static/icon_monochrome_instagram.svg",
        alt: "Instagram",
    }
]

export default function SocialButtonBar() {
    
    return (
        <div
            className="social-buttons-bar"
        >
            {
                buttons.map((button) => {
                    return (
                        <SocialButton
                            href={button.href}
                            className={button.className}
                            image={button.image}
                            alt={button.alt}
                        />
                    )
                })
            }
        </div>
    )
}

